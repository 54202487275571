// Libraries
import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { Box } from '@mui/material';

// Assets
import '../assets/css/global.css';
import '../assets/css/scrollbar.css';

import { SnakeLoader } from '../core/loader/SnakeLoader';
import { SIPLogo } from '../core/global/Icons';
import Pofsis from '../assets/images/logos/pofsis.png'

import { domain } from '../core/Env';

export const Index = () => {
   
    useEffect(() => {
		setTimeout(() => {
			window.location.href = `${domain('tool').psl}/index`
		}, 1000);
        console.log('POFSUREiPASS V2.1.4')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Box mt={0.5} zIndex={2}>
					<SIPLogo size={60}/>
				</Box>
				<Box fontSize="20px" mt="20px" mb="20px">
					<Box component="span" fontWeight={600}>SUREiPASS</Box>
				</Box>
				<SnakeLoader size="8px" bg="#0070EF" distance="0.1rem" />
				<Box mt="20px" fontSize="12px" color="#52616B">Please wait, It take only 1 minute...</Box>
				<Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '26px'}}>
					<img src={Pofsis} alt={Pofsis} />
					<Box ml="16px" fontSize="16px" fontWeight={600}>POFSIS</Box>
				</Box>
			</Box>
        </>
    )
}