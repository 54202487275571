import { useState } from "react"

export const HomeStates = () => {
    const [categories, setCategories] = useState({personal: null, business: null})
    const [selected, setSelected] = useState(null)
    const [openTour, setOpenTour] = useState(false);
    const [onboarding, setOnboarding] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(true);
    const [inquiry, setInquiry] = useState(0);
    const [myReasons, setMyReasons] = useState({isLoading: true, reasons: null, image: null})
    const [myPlan, setMyPlan] = useState({isLoading: true, plan: null, progress_level: null}) /* to be removed */
    const [planToTake, setplanToTake] = useState({isLoading: true, when: null, licensure_id: null, certification_id: null, exam_name: null})
    const [progressLevel, setprogressLevel] = useState({isLoading: true, data: []})
    return {
        categories: {data: categories, set: setCategories},
        selected: {data: selected, set: setSelected},
        openTour: {data: openTour, set: setOpenTour},
        onboarding: {data: onboarding, set: setOnboarding},
        openSuccess: {data: openSuccess, set: setOpenSuccess},
        inquiry: {data: inquiry, set: setInquiry},
        myReasons: {data: myReasons, set: setMyReasons},
        myPlan: {data: myPlan, set: setMyPlan},
        planToTake: {data: planToTake, set: setplanToTake},
        progressLevel: {data: progressLevel, set: setprogressLevel}
    }
}