import { Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { WebRoute } from "../core/webrouter/Business";
import { SnakeLoader } from '../core/loader/SnakeLoader'
import { SIPBizLogo } from '../core/global/Icons';
import { Box } from '@mui/material/';
import { ToolContext } from '../core/context/ToolContext';
import { rqx } from '../core/request/API';
import { env } from '../core/Env';
import moment from 'moment';
import { InstallPromotion } from '../__business/components/layouts/installation/InstallPromotion';

const __SESSION = JSON.parse(localStorage.getItem('sip_biz_session'))

export const Core = () => {
	const { tool_state, install_state } = useContext(ToolContext)
    const __installer = () => {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			install_state.prompt.set(e)
		});
	}
    const installPromptHandler = () => {
		setTimeout(() => {
			install_state.ip.set({stat: true, prompt_type: 'first_prompt'})
		}, 10000);
	}
	const saveUserLogs = async() => {
		let data = {
			action: "signin",
			aid: __SESSION.ainfo.aid,
			date: moment(new Date()).format('YYYY-MM-DD'),
			tool_id: JSON.parse(process.env.REACT_APP_TID_BIZ)
		}
		let post = await rqx.post(`<<global>>/${env()}/trail/create`, data)
		console.log(post)
	}
    useEffect(() => {
		const __init = async () => {
			let ainfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: __SESSION.ainfo.aid})
			let pinfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/primeinfo/read`, {aid: __SESSION.ainfo.aid})
			let uaccess = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/roleaccess/uap`, {aid: __SESSION.ainfo.aid, tid: process.env.REACT_APP_TID_BIZ})
			const ulicensure = await rqx.get(`<<tool>>/${env()==='local'?'dev':env()}/licensure/get`, {"name": "CPA"})
			if(uaccess[0].role_name === 'Regular User'){
				saveUserLogs();
			}
			await rqx.post(`<<global>>/${env()==='local'?'dev':env()}/trail/create`, {action:'signin', aid: __SESSION.ainfo.aid, date:moment(new Date()).format('YYYY-MM-DD'), tool_id: parseInt(process.env.REACT_APP_TID_BIZ) })
			await tool_state.__SESSION.set({ainfo, pinfo, uaccess, ulicensure})
			await tool_state.prefetch.set(true)
			// RUN PWA INSTALLATION PROMPT HANDLER
			// installPromptHandler()
		}
		
		if (__SESSION !== null) {
			__installer()
			__init()
		} else {
			setTimeout(() => {
				// SET STATES HERE ( SAMPLE STORING BELOW: )
				tool_state.prefetch.set(true)

				// RUN PWA INSTALLATION PROMPT HANDLER
				installPromptHandler()
			}, 1000);
		}
		console.log(`POFSUREiPASS v4.1.4 is running`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		
	}, [])
    return (
        <Box width="100%" height="100%">
            {
				tool_state.prefetch.data ? (
					<Router>
						<Suspense fallback={
							<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
								<SIPBizLogo size={60}/>
								<Box mt={4}>
									<SnakeLoader size="1rem" bg="#165320" distance="0.3rem" />
								</Box>
							</Box>
						}>
							<WebRoute />
						</Suspense>
					</Router>
				) : (
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<SIPBizLogo size={60}/>
						<Box mt={4}>
							<SnakeLoader size="1rem" bg="#165320" distance="0.3rem" />
						</Box>
					</Box>
				)
			}
			<InstallPromotion install_state={install_state} />
        </Box>
    )
}