import { lazy } from 'react';
import { Box, Container } from '@mui/material';
import { Route, Switch, useLocation } from 'react-router-dom';
import { VM } from '../VM';
let view = VM();
const ExamType = lazy(() => import(`../../__ubr/components/examType${view}/Index`))
const Select = lazy(() => import(`../../__ubr/components/examType${view}/Select`))
const TakeExam = lazy(() => import(`../../__ubr/components/takeExam${view}/Index`))
const MyReviewer = lazy(() => import(`../../__ubr/components/myReviewer${view}/Index`))
export const WebRoutev1 = () => {
    const location = useLocation().pathname;
    return (
        <Switch>
            <Route exact path="/ubr" render={props=>(Render(ExamType, props, location))} />
            <Route exact path="/ubr/select" render={props=>(Render(Select, props, location))} />
            <Route exact path="/ubr/take-exam/:sub" render={props=>(Render(TakeExam, props, location))} />
            <Route exact path="/ubr/my-reviewer/:page/:sub" render={props=>(Render(MyReviewer, props, location))} />
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}
const Render = (Component, props, location) => {
    return (
        <Box width="100%" height="100%">
            {
                view === '' ? (
                    <Box height="100%" width="100%"><Component {...props} /></Box>
                ) : (
                    <Box width="100%" height="100%">
                        <Component {...props}/>
                    </Box>
                )
            }
        </Box>
    )
}