import { Autocomplete, Box, Button, Drawer, IconButton, InputLabel, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { rqx } from '../../../../../../core/request/API';
import { env } from '../../../../../../core/Env';
import { SnakeLoader } from '../../../../../../core/loader/SnakeLoader';
import { Close } from '../../../../../../core/global/Icons';
import { fetchSEDProgress } from '../../Functions';
let hasValue = {};
export const SED = ({__SESSION, sedProgress, setSEDProgress, setSettings, settings}) => {
    const [selected, setSelected] = useState({subject_id: '', topic_id: '', set: '', level: '', take: '', technique: ''})
    const [input, setInput] = useState({subjects: [], topics: [], sets: [], levels: [], takes: [], techniques: ['All', 'Sure', 'Easy', 'Difficult']})
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(true)
    useEffect(() => {
        fetchData({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), licensure_id: __SESSION.licensure, fetch: 'subjects'})
        fetchDefaultData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const fetchData = async (params) => {
        let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/fetchSEDSetting`, params)
        if(fetch.length > 0){
            let name = params.fetch
            if(name === 'sets'){
                setInput({...input, [name]: fetch})
            }else if(name === 'takes'){
                let data = []
                for (let i = 0; i < fetch.length; i++) {
                    data.push({id: fetch[i].id, name: fetch[i].take})
                }
                setInput({...input, [name]: data})
            }else{
                setInput({...input, [name]: fetch})
            }
        }
    }
    const fetchDefaultData = async() => {
        let takenSubs = await rqx.get(`<<tool>>/${env()}/examActivity/fetchSEDSetting`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), licensure_id: __SESSION.licensure, fetch: 'subjects'})
        if(takenSubs.length > 0){
            let subject = takenSubs.filter(f => f.name === sedProgress.summary.subject)
            let takenTops = await rqx.get(`<<tool>>/${env()}/examActivity/fetchSEDSetting`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), 'subject_id': subject[0].subject_id, fetch: 'topics'})
            if(takenTops.length > 0){
                let topic = takenTops.filter(f => f.name === sedProgress.summary.topic)
                let takenSets = await rqx.get(`<<tool>>/${env()}/examActivity/fetchSEDSetting`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), 'topic_id': topic[0].user_topic_id, fetch: 'sets'})
                if(takenSets.length > 0){
                    let takenLevels = takenSets.filter(f => f.name === sedProgress.summary.set)
                    let levels = []
                    for (let i = 0; i < takenLevels.length; i++) {
                        levels.push({id: takenLevels[i].user_set_id, name: takenLevels[i].category_type === 1 ? 'Basic' : takenLevels[i].category_type === 2 ? 'Intermediate' : 'Advance'})
                    }
                    if(levels.length > 0){
                        let level = takenLevels.filter(f => f.category_type === sedProgress.summary.category_type)
                        let takenTakes = await rqx.get(`<<tool>>/${env()}/examActivity/fetchSEDSetting`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), 'user_set_id': level[0].user_set_id, fetch: 'takes'})
                        let takes = []
                        for (let i = 0; i < takenTakes.length; i++) {
                            takes.push({id: takenTakes[i].id, name: takenTakes[i].take})
                        }
                        setInput({...input, 'subjects' : takenSubs, 'topics': takenTops, 'sets': takenSets, 'levels' : levels, 'takes': takes})
                        let take = takenTakes.filter(f => f.take === sedProgress.summary.take)
                        setSelected({subject_id: subject[0].subject_id, topic_id: topic[0].user_topic_id, set: sedProgress.summary.set, level: level[0].user_set_id, take: take[0].id, technique: sedProgress.summary.technique})
                    }
                }
            }
        }
    }
    const handleChange = async(e, val, name, fetch) => {
        let value = null
        if(val !== null ){
            if(name === 'subject_id'){
                value = val.subject_id
                fetchData({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), [name]: val.subject_id, fetch: fetch})
            }else if(name === 'topic_id'){
                value = val.user_topic_id
                fetchData({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), [name]: val.user_topic_id, fetch: fetch})
            }else if(name === 'set'){
                value = val.name
                const data = input.sets.filter(f=>f.name === val.name)
                let levels = []
                for (let i = 0; i < data.length; i++) {
                    levels.push({id: data[i].user_set_id, name: data[i].category_type === 1 ? 'Basic' : data[i].category_type === 2 ? 'Intermediate' : 'Advance'})
                }
                setInput({...input, 'levels': levels})
            }else if(name === 'level'){
                value = val.id
                fetchData({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), user_set_id: val.id, fetch: fetch})
            }else if(name === 'take'){
                value = val.id
            }
        }
        if(name === 'technique'){
            value = e.target.value
        }
        insertInput(name, value);
    }
    const insertInput = (name, value) => {
        setSelected({...selected, [name]: value})
        hasValue[name] = value
        let d = Object.keys(hasValue).every((k) => hasValue[k] !== null)
        if(Object.keys(hasValue).length === 6 && d){
            setDisable(false)
        }else{
            setDisable(true)
        }
    }
    const handleGenerate = async()=> {
        setLoading(true);
        setSEDProgress({...sedProgress, stat: 'loading'})
        let subject = input.subjects.filter(f => f.subject_id === selected.subject_id)[0]
        let topic = input.topics.filter(f => f.user_topic_id === selected.topic_id)[0]
        let take = input.takes.filter(f => f.id === selected.take)[0]
        let user_set_id = input.sets.filter(f => f.user_set_id === selected.level)[0]
        let summary = {subject: subject.name, topic: topic.name, set: selected.set, category_type: user_set_id.category_type, take: take.name, technique: selected.technique};
        let technique = selected.technique === "All" ? "All" : (selected.technique === "Sure" ? 1 : selected.technique === "Easy" ? 2 : 3);
        console.log(user_set_id, summary)
        fetchSEDProgress({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), from: 'setting', user_set_id: user_set_id.user_set_id, category_type: technique, take: take.id, technique: selected.technique}, summary, sedProgress, setSEDProgress, 'desktop')
        setTimeout(async() => {
            setSettings({open: false, for: ''})
            setLoading(false)
        }, 1000);
    }
    const handleClose = () => {
        setSettings({open: false, for: ''})
        hasValue = {};
    }
    return (
        <Drawer anchor={'right'} open={settings.open} sx={{"& .MuiDrawer-paper": {overflowY: "visible", mt: "0px"}, transition: 'width 0.3s ease 0s', zIndex: 1202}}>
            <Box position="absolute" left="-60px" width="44px" height="44px" bgcolor="#fff" display="flex" justifyContent="center" alignItems="center" borderRadius="50px" sx={{top: '10px'}}>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <Box width="340px">
                <Box pt="16px" pl="16px" pr="16px">
                    <Box mb="5px">
                        <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Subjects</InputLabel>
                        <Autocomplete 
                            defaultValue={{ name: selected.subject_id === '' ? sedProgress.summary.subject : selected.subject_id }}
                            options={input.subjects}
                            onChange={(e, value) => handleChange(e, value, 'subject_id', 'topics')} 
                            getOptionLabel={(option) =>  option.name}
                            renderInput={(params) => <TextField {...params}/>} 
                        />
                    </Box>
                    <Box mb="5px">
                        <InputLabel  sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Topic</InputLabel>
                        <Autocomplete 
                            defaultValue={{ name: selected.topic_id === '' ? sedProgress.summary.topic : selected.topic_id }}
                            options={input.topics}
                            onChange={(e, value) => handleChange(e, value, 'topic_id', 'sets')} 
                            getOptionLabel={(option) =>  option.name}
                            renderInput={(params) => <TextField {...params}/>} 
                        />
                    </Box>
                    <Box mb="5px">
                        <InputLabel  sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Set</InputLabel>
                        <Autocomplete 
                            defaultValue={{ name: selected.set === '' ? sedProgress.summary.set : selected.set }}
                            options={input.sets}
                            onChange={(e, value) => handleChange(e, value, 'set', 'levels')} 
                            getOptionLabel={(option) =>  option.name}
                            renderInput={(params) => <TextField {...params}/>} 
                        />
                    </Box>
                    <Box mb="5px">
                        <InputLabel  sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Level</InputLabel>
                        <Autocomplete 
                            defaultValue={{ name: selected.level === '' ? (sedProgress.summary.category_type === 1 ? 'Basic' : sedProgress.summary.category_type === 2 ? 'Intermediate' : 'Advance') : selected.level }}
                            options={input.levels}
                            onChange={(e, value) => handleChange(e, value, 'level', 'takes')} 
                            getOptionLabel={(option) =>  option.name}
                            renderInput={(params) => <TextField {...params}/>} 
                        />
                    </Box>
                    <Box mb="5px">
                        <InputLabel  sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Takes</InputLabel>
                        <Autocomplete 
                            defaultValue={{ name: selected.take === '' ? sedProgress.summary.take : selected.take }}
                            options={input.takes}
                            onChange={(e, value) => handleChange(e, value, 'take', 'techniques')} 
                            getOptionLabel={(option) =>  option.name}
                            renderInput={(params) => <TextField {...params}/>} 
                        />
                    </Box>
                    <Box mb="5px">
                        <InputLabel  sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Technique</InputLabel>
                        <TextField id="outlined-select-currency" name="technique" select value={selected.technique === '' ? sedProgress.summary.technique : selected.technique} onChange={(e)=>handleChange(e, null, 'technique', null)} fullWidth>
                            {
                                input.techniques.map((t,i) => (
                                    <MenuItem key={i} value={t}>{t}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Box>
                    <Button variant="contained" sx={{width: '100%'}} onClick={handleGenerate} >
                        {
                            loading ? (
                                <Box height="25px" display="flex" alignItems="center" justifyContent="center"><SnakeLoader size="10px" bg="#fff" distance="0.3rem" /></Box>
                            ) : (
                                'Generate'
                            )
                        }
                    </Button>
                </Box>
            </Box>
        </Drawer>
    )
}