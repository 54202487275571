import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import { SnakeLoader } from '../core/loader/SnakeLoader';
import { SIPLogo } from '../core/global/Icons';
import ErrorBot from '../assets/images/core/error_bot.jpg'
import Pofsis from '../assets/images/logos/pofsis.png'
import { rqx } from '../core/request/API';
import { env } from '../core/Env';
export const SSORqx = () => {
    return <Router><Validate /></Router>
}

const Validate = () => {
    const q = new URLSearchParams(useLocation().search);
    const [err, setErr] = useState(false)
    
    useEffect(() => {
        const __init = async () => {
            if (q.get('ainfo') !== null) {
                const ainfo = JSON.parse(q.get('ainfo'))
                const acc = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: ainfo.aid})
                if (typeof acc === 'object') {
                    setErr(false)
                    let d = new Date()
                    d.setDate(d.getDate() + 30);
                        
                    const strg = {
                        expiration: `${d.getMonth()+1}${d.getUTCDate()}${d.getUTCFullYear()}`,
                        ainfo: ainfo
                    }
                    localStorage.setItem('sip_session', JSON.stringify(strg))
                    if(q.get('subcb') !== null && q.get('subcb') !== 'null'){
                        const savedExams = JSON.parse(localStorage.getItem('sip_psl_ubr_session'))
                        let savedR = savedExams.filter(f => f.from === 'my-reviewer')
                        let savedTE = savedExams.filter(f => f.from === 'take-exam')
                        if(savedR.length > 0){
                            if(parseInt(savedR[0].etype) === 0){
                                let saveExamType = await rqx.post(`<<tool>>/${env()}/myReviewer/createExamType`, {certification_id: parseInt(savedR[0].etid), licensure_id: 0, created_by: parseInt(window.atob(ainfo.aid).split('_')[1])})
                                if(saveExamType.msg === 'success' || saveExamType.msg === 'exist'){
                                    savePlan(savedR[0], parseInt(window.atob(ainfo.aid).split('_')[1]))
                                    saveRevSubject(saveExamType.id, parseInt(window.atob(ainfo.aid).split('_')[1]), savedR[0])
                                }else{
                                    redirectToReviewer()
                                }
                            }else{
                                let saveExamType = await rqx.post(`<<tool>>/${env()}/myReviewer/createExamType`, {certification_id: 0, licensure_id: parseInt(savedR[0].etid), created_by: parseInt(window.atob(ainfo.aid).split('_')[1])})
                                if(saveExamType.msg === 'success' || saveExamType.msg === 'exist'){
                                    savePlan(savedR[0], parseInt(window.atob(ainfo.aid).split('_')[1]))
                                    saveRevSubject(saveExamType.id, parseInt(window.atob(ainfo.aid).split('_')[1]), savedR[0])
                                }else{
                                    redirectToReviewer()
                                }
                            }
                        }else if(savedTE.length > 0){
                            if(savedTE[0].takes.length > 0){
                                window.location.href = '/psl/take-exam'
                            }
                        }
                    }else{
                        window.location.href = '/psl'
                    }
                } else {
                    setErr(true)
                }
            }
        }
        const saveRevSubject = async(rtid, aid, savedR) => {
            let allQuestions = savedR.equestions;
            let questions = []
            for (let i = 0; i < allQuestions.length; i++) {
                // eslint-disable-next-line no-useless-escape
                questions.push({alloted_time: allQuestions[i].alloted_time, answer: allQuestions[i].answer, choices: JSON.stringify(allQuestions[i].choices), question: allQuestions[i].question.replace(/[\*\^\'\!]/g,"").replace("\n","*").replace("*","\n"), solution: allQuestions[i].solution === "" || allQuestions[i].solution === null || allQuestions[i].solution === undefined  ? "None" : allQuestions[i].solution.replace(/[\*\^\'\!]/g,"").replace("\n","*").replace("*","\n"), isSave: 0})
            }
            if(questions.length > 0){
                let saveSubject = await rqx.post(`<<tool>>/${env()}/myReviewer/createSubject`, {reviewer_type_id: rtid, subject_type_id: parseInt(savedR.subtid), created_by: aid})
                if(saveSubject.msg === 'success' || saveSubject.msg === 'exist'){
                    let saveExam = await rqx.post(`<<tool>>/${env()}/myReviewer/createExam`, {rueid: 0, name: savedR.ename, user_subject_id: saveSubject.id, created_by: aid, questions: questions})
                    if(saveExam.msg === 'success'){
                        redirectToReviewer()
                    }
                }
            }
        }
        const savePlan = async(reviewer, aid) => {
            let params = {aid: aid, month: moment(reviewer.etplan).format('M'), year: moment(reviewer.etplan).format('YYYY'), day: moment(reviewer.etplan).format('D'), certification_id: parseInt(reviewer.etype) === 0 ? parseInt(reviewer.etid) : 0, licensure_id: parseInt(reviewer.etype) === 1 ? parseInt(reviewer.etid) : 0, exam_name: reviewer.etname}
            let save = await rqx.post(`<<tool>>/${env()}/userPlan/createv2`, params)
        }
        const redirectToReviewer = () => {
            localStorage.removeItem('sip_psl_ubr_session')
            window.location.href = '/psl/my-reviewer/exam-type/index'
        }
        setTimeout(() => {
            __init()
        }, 2400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return !err ? (
        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box mt={0.5} zIndex={2}>
                <SIPLogo size={60}/>
            </Box>
            <Box fontSize="20px" mt="20px" mb="20px">
                <Box component="span" fontWeight={600}>SUREiPASS</Box> Personal
            </Box>
            <SnakeLoader size="8px" bg="#0070EF" distance="0.1rem" />
            {
                q.get('subcb') === null ? <Box mt="20px" fontSize="12px" color="#52616B">Please wait, It take only 1 minute...</Box> : <Box mt="20px" fontSize="12px" color="#52616B">Please wait, we are saving your exams. It take only 1 minute...</Box>
            }
            <Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '26px'}}>
                <img src={Pofsis} alt={Pofsis} />
                <Box ml="16px" fontSize="16px" fontWeight={600}>POFSIS</Box>
            </Box>
        </Box>
    ) : (
        <Box height="100%" width="100%" display="flex" justifyContent="center" bgcolor="#ffffff">
            <Box height="100%" width="400px" display="flex" justifyContent="center" alignItems="center">
                <Grid container>
                    <Grid item xs={12}>
                        <img src={ErrorBot} alt={ErrorBot} width="100%" />
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <Box color="primary.main" fontSize={14} >POF<strong>SUREiPASS</strong></Box>
                            <Box fontSize={24}><b>SHEEESSHH!</b></Box> 
                            {/* <Box fontSize={18} mb={3}>Looks like an expolosion...</Box> */}
                            <Box fontSize={18} mb={3}>Well, this is unexpected. . .</Box>
                            <Box textAlign="justify" fontSize={14} mb={1}>
                                We were unable to authenticate you because your tool did not provide the proper authorization. Please contact your tool's customer support for assistance with this issue.
                            </Box>
                            <Box textAlign="justify" fontSize={14} color="gray">
                                Error code: AUTHORIZATION_ERROR. AID is invalid.
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}