import { useState } from "react"
import { Leaderboard1, Leaderboard2, Home1, POFChatLogo, Notification1, TakeExam1, TakeExam2, MyReviewer } from '../../global/Icons';

export const NavigationStates = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [changeWidth, setChangeWidth] = useState(true)
    const [leftBar, setLeftbar] = useState(true)
    const [more, setMore] = useState(false)
    const [myTool, setMyTool] = useState(false)
    const [notif,  setNotif] = useState(false)
    const [psltnReferral, setPsltnReferral] = useState(true)
    const [pslToolTip, setPslToolTip] = useState(false)
    const [tabs, setTabs] = useState({
        top: [
            {name:'Chat', subdir:'/', ref: '__chat', not_active:<POFChatLogo size="24px" br="100%"/>, active:<POFChatLogo size="24px" br="100%"/>},
            {name:'Notifications', subdir:'/', ref: '/__notif', not_active:<Notification1 fill="#283745"/>, active:<Notification1 fill="#52616B"/>},
        ],
        left: [
            {name:'Dashboard', subdir:'/psl', ref: undefined, tour: 'first-step', not_active:<Home1 stroke="#8496BB"/>, active:<Home1 fill="#081c45" stroke="#E8E8E8"/>},
            {name:'Take Exam', subdir:'/psl/take-exam', ref: 'take-exam', tour:'second-step',not_active:<TakeExam1/>, active:<TakeExam2 fill="#081c45" stroke="#081C45"/>},
            {name:'My Reviewer', subdir:'/psl/my-reviewer/exam-type/index', ref: 'my-reviewer', tour:'second-step',not_active:<MyReviewer/>, active:<MyReviewer fill="#081c45" stroke="#081C45"/>},
            {name:'Leaderboard', subdir:'/psl/leaderboard', ref: 'leaderboard', tour:'third-step', not_active:<Leaderboard1 stroke="#8496BB" />, active:<Leaderboard2 fill="#081c45" stroke="#081C45"/>},
        ],
        bottom: [
            {name:'Dashboard', subdir:'/psl', ref: '/psl', tour: 'first-step', not_active:<Home1 stroke="#8496BB"/>, active:<Home1 fill="#256EFF" stroke="#E8E8E8"/>},
            {name:'Take Exam', subdir:'/psl/take-exam', ref: '/psl/take-exam', tour:'second-step', not_active:<TakeExam1 stroke="#5A6783" />, active:<TakeExam2 fill="#256EFF" stroke="#256EFF"/>},
            {name:'My Reviewer', subdir:'/psl/my-reviewer/exam-type/index', ref: 'my-reviewer', tour:'second-step',not_active:<MyReviewer stroke="#5A6783" />, active:<MyReviewer fill="#256EFF" stroke="#256EFF"/>},
            {name:'Leaderboard', subdir:'/psl/leaderboard', ref: '/psl/leaderboard', tour:'third-step', not_active:<Leaderboard1 stroke="#5A6783" />, active:<Leaderboard2 fill="#256EFF" stroke="#256EFF"/>},
        ]
    })
    const [tc, setTc] = useState({open: true, list: false})

    return {
        anchorEl: {data: anchorEl, set: setAnchorEl},
        changeWidth: {data: changeWidth, set: setChangeWidth},
        leftBar: {data: leftBar, set: setLeftbar},
        tabs: {data: tabs, set: setTabs},
        tc: {data: tc, set: setTc},
        more: {data: more, set: setMore},
        myTool: {data: myTool, set: setMyTool},
        notif: {data: notif, set: setNotif},
        psltnReferral: {data: psltnReferral, set: setPsltnReferral},
        pslToolTip: {data: pslToolTip, set: setPslToolTip}
    }
}