import { Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box } from '@mui/material/';
import { ToolContext } from '../core/context/ToolContext';
import { WebRoutev1 } from "../core/webrouter/UBR";
import { SnakeLoader } from '../core/loader/SnakeLoader'
import { SIPLogo } from '../core/global/Icons';
import Pofsis from '../assets/images/logos/pofsis.png'

export const Core = () => {
	const { tool_state } = useContext(ToolContext)
	
	
        useEffect(() => {
            const __init = async () => {
                // RUN PWA INSTALLATION PROMPT HANDLER
                // installPromptHandler()
            }
			__init()
            setTimeout(() => {
				// SET STATES HERE ( SAMPLE STORING BELOW: )
				tool_state.prefetch.set(true)

			}, 1000);
		    console.log(`POFSUREiPASS v2.0.1 is running`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		
	}, [])
	return (
		<Box width="100%" height="100%">
			{
				tool_state.prefetch.data ? (
					<Router>
						<Suspense fallback={
							<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
								<Box mt={0.5} zIndex={2}>
									<SIPLogo size={60}/>
								</Box>
								<Box fontSize="20px" mt="20px" mb="20px">
									<Box component="span" fontWeight={600}>SUREiPASS</Box> Personal
								</Box>
								<SnakeLoader size="8px" bg="#0070EF" distance="0.1rem" />
								<Box mt="20px" fontSize="12px" color="#52616B">Please wait, It take only 1 minute...</Box>
								<Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '26px'}}>
									<img src={Pofsis} alt={Pofsis} />
									<Box ml="16px" fontSize="16px" fontWeight={600}>POFSIS</Box>
								</Box>
							</Box>
						}>
                            <WebRoutev1 />
						</Suspense>
					</Router>
				) : (
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<Box mt={0.5} zIndex={2}>
							<SIPLogo size={60}/>
						</Box>
						<Box fontSize="20px" mt="20px" mb="20px">
							<Box component="span" fontWeight={600}>SUREiPASS</Box> Personal
						</Box>
						<SnakeLoader size="8px" bg="#0070EF" distance="0.1rem" />
						<Box mt="20px" fontSize="12px" color="#52616B">Please wait, It take only 1 minute...</Box>
						<Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '26px'}}>
							<img src={Pofsis} alt={Pofsis} />
							<Box ml="16px" fontSize="16px" fontWeight={600}>POFSIS</Box>
						</Box>
					</Box>
				)
			}
		</Box>
	)
}