import { Suspense, useContext, useEffect, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box } from '@mui/material/';
import moment from 'moment';
import { ToolContext } from '../core/context/ToolContext';
import { WebRoutev1 } from "../core/webrouter/Personal";
import { SnakeLoader } from '../core/loader/SnakeLoader'
import { SIPLogo } from '../core/global/Icons';
import Pofsis from '../assets/images/logos/pofsis.png'
import { InstallPromotion } from '../__personal/components/layouts/installation/InstallPromotion';
import { rqx } from '../core/request/API';
import { env } from '../core/Env';
import { VM } from '../core/VM';
let view = VM();
const Personalization = lazy(() => import(`../__personal/components/personalization${view}/Index`))

const __SESSION = JSON.parse(localStorage.getItem('sip_session'))
const tool_id =  process.env.REACT_APP_TID;

export const Core = () => {
	const { tool_state, install_state, home_state } = useContext(ToolContext)
	const __installer = () => {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			install_state.prompt.set(e)
		});
	}

	const installPromptHandler = () => {
		setTimeout(() => {
			install_state.ip.set({stat: true, prompt_type: 'first_prompt'})
		}, 10000);
	}
	
	useEffect(() => {
		const __init = async () => {
			let ainfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: __SESSION.ainfo.aid})
			let pinfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/primeinfo/read`, {aid: __SESSION.ainfo.aid})
			let uaccess = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/roleaccess/uap`, {aid: __SESSION.ainfo.aid, tid:tool_id})
			let uaddress = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/location/assigned`, {aid:__SESSION.ainfo.aid})
			let owned = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/tools/owned`, {aid: __SESSION.ainfo.aid, platform: 'personal'})
			let dtowned = owned.filter(f => { return f.tid === parseInt(process.env.REACT_APP_TID) })
			let isAccountant = await rqx.get(`<<tool>>/${env()==='local'?'dev':env()}/userPlan/isAccountant`, {"created_by": parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1])})
			const userLicensure = await rqx.get(`<<tool>>/${env()==='local'?'dev':env()}/licensure/get`, {"name": "Certified Public Accountant"})
			let licensure = userLicensure[0].id	
			let planToTake = await rqx.get(`<<tool>>/${env()}/userPlan/read`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1])})
			if(planToTake.msg === 'success'){
				home_state.planToTake.set({isLoading: false, when: planToTake.when.length === 0 ? null : planToTake.when, licensure_id: planToTake.licensure_id, certification_id: planToTake.certification_id, exam_name: planToTake.exam_name === '' ? null : planToTake.exam_name})
			}
			await rqx.post(`<<global>>/${env()==='local'?'dev':env()}/trail/create`, {action:'signin', aid: __SESSION.ainfo.aid, date:moment(new Date()).format('YYYY-MM-DD'), tool_id: parseInt(process.env.REACT_APP_TID) })
			tool_state.__SESSION.set({ainfo, pinfo, uaccess, uaddress, licensure, isAccountant})
			tool_state.prefetch.set(true)
			tool_state.subPlan.set(dtowned);
			// RUN PWA INSTALLATION PROMPT HANDLER
			// installPromptHandler()
		}
		
		if (__SESSION !== null) {
			__installer()
			__init()
		} else {
			setTimeout(() => {
				// SET STATES HERE ( SAMPLE STORING BELOW: )
				tool_state.prefetch.set(true)

				// RUN PWA INSTALLATION PROMPT HANDLER
				installPromptHandler()
			}, 1000);
		}
		console.log(`POFSUREiPASS v2.0.7 is running`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		
	}, [])
	return (
		<Box width="100%" height="100%">
			{
				tool_state.prefetch.data ? (
					<Router>
						<Suspense fallback={
							<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
								<Box mt={0.5} zIndex={2}>
									<SIPLogo size={60}/>
								</Box>
								<Box fontSize="20px" mt="20px" mb="20px">
									<Box component="span" fontWeight={600}>SUREiPASS</Box> Personal
								</Box>
								<SnakeLoader size="8px" bg="#0070EF" distance="0.1rem" />
								<Box mt="20px" fontSize="12px" color="#52616B">Please wait, It take only 1 minute...</Box>
								<Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '26px'}}>
									<img src={Pofsis} alt={Pofsis} />
									<Box ml="16px" fontSize="16px" fontWeight={600}>POFSIS</Box>
								</Box>
							</Box>
						}>
							<WebRoutev1 />
							<Personalization __SESSION={tool_state.__SESSION.data} onboarding={home_state.onboarding.data} planToTake={home_state.planToTake.data}/>
						</Suspense>
					</Router>
				) : (
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<Box mt={0.5} zIndex={2}>
							<SIPLogo size={60}/>
						</Box>
						<Box fontSize="20px" mt="20px" mb="20px">
							<Box component="span" fontWeight={600}>SUREiPASS</Box> Personal
						</Box>
						<SnakeLoader size="8px" bg="#0070EF" distance="0.1rem" />
						<Box mt="20px" fontSize="12px" color="#52616B">Please wait, It take only 1 minute...</Box>
						<Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '26px'}}>
							<img src={Pofsis} alt={Pofsis} />
							<Box ml="16px" fontSize="16px" fontWeight={600}>POFSIS</Box>
						</Box>
					</Box>
				)
			}
			<InstallPromotion install_state={install_state} />
		</Box>
	)
}